<template>
  <div class="home">
    <!--    <div class="marquee"><span>First time purchase get extra 5% off, use code: WELCOME</span></div>-->
    <div class="banner" v-if="homeBanner && homeBanner.length">
      <swiper :options="swiperOptionTop">
        <swiper-slide v-for="(item, index) in homeBanner" :key="index">
         <a :href="item.url"><img :src="isshow?item.image_pc:item.image_mobile" :alt="item.name"></a>
        </swiper-slide>
        <!-- <swiper-slide v-for="(item, index) in isshow?pcBannerList:phoneBannerList" :key="index">
          <img :src="item" :alt="item">
        </swiper-slide> -->
        <div class="swiper-pagination" slot="pagination"></div>
        <!-- <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div> -->
      </swiper>
    </div>
    <div class="home-img">
      <swiper :options="swiperOption" v-if="imgList && imgList.length">
        <swiper-slide v-for="(item, index) in imgList" :key="index">
          <a :href="item.url"><img :src="item.image" :alt="item.name"></a>
        </swiper-slide>
        <!--<div class="swiper-pagination" slot="pagination"></div>
         <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div> -->
      </swiper>
    </div>

    <!-- pc端 -->
    <div class="home-intro">
      <div class="home-intro-div" v-for="(item, index) in introList" :key="index">
        <img :class="item.type == 1 ? 'imgs' : ''" :src="item.imgUrl" :alt="item.title">
        <div>
          <p style="margin: 0">{{ item.title }}</p>
          <span>{{ item.span }}</span>
        </div>
      </div>
    </div>
    <!-- 手机端 -->
    <div class="home-intro-swiper">
      <swiper :options="swiperOption2">
        <swiper-slide v-for="(item, index) in introList" :key="index">
          <img :class="item.type == 1 ? 'imgs' : ''" :src="item.imgUrl" :alt="item.title">
          <div>
            <p style="margin: 0">{{ item.title }}</p>
            <span>{{ item.span }}</span>
          </div>
        </swiper-slide>
      </swiper>
    </div>


    <div class="home-box">
      <div class="home-box-title">
        <h1>熱門國家方案</h1>
        <div @click="$router.push('/product')">
          更多
          <img src="@/assets/image/icon/icon-arrow.png" alt="">
        </div>
      </div>
      <div class="home-box-list">
        <div class="list-div" v-for="(item, index) in dataList" :key="index" @click="toDetails(item)">
          <div class="list-div-img">
            <img class="img" :src="item.image" :alt="item.name">
            <div class="shop" @click.stop="toShop(item)">
              <img src="@/assets/image/icon/icon-shop.png" alt="">
            </div>
            <div class="love" @click.stop="toLove(item)" v-if="false">
              <img src="@/assets/image/icon/icon-love.png" alt="">
            </div>
          </div>
          <h2 class="list-div-h2">{{ item.sketch }}</h2>
          <h3 class="list-div-h3">{{ item.name }}</h3>
          <h4 class="list-div-h4">
            <!-- ~ USD <span>{{'$ ' + item.max_price}}</span> -->
            <span>{{ '$NT ' + item.min_price.toLocaleString() }} 起</span>
          </h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 引入插件
import {swiper, swiperSlide} from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import {getIndexbanner, getPlanList, getCurrentRate, getBannerList} from "@/request/api"
import Login from "../../components/User/Login.vue";

export default {
  name: "home",
  components: {
    swiper,
    swiperSlide
  },
  data() {
    return {
      homeBanner: [],
      isshow: window.innerWidth > 768 ? true : false,
      pcBannerList: [
        require('@/assets/image/home/4.jpg'),
        require('@/assets/image/home/5.jpg'),
        require('@/assets/image/home/6.jpg'),
      ],
      phoneBannerList: [
        require('@/assets/image/home/1.jpg'),
        require('@/assets/image/home/2.jpg'),
        require('@/assets/image/home/3.jpg'),
      ],

      // 头部轮播列表
      imgList: [],
      // 介绍列表
      introList:
          [
            {
              type: 0,
              imgUrl: require('@/assets/image/icon/icon-vector.png'),
              title: '服務涵蓋110+個國家',
              span: 'eSIM國家方案範圍廣，想去哪裡都可以'
            },
            {
              type: 1,
              imgUrl: require('@/assets/image/icon/icon-settings.png'),
              title: '專業服務10+年經驗',
              span: '竭盡所能為您提供最好的服務'
            },
            {
              type: 0,
              imgUrl: require('@/assets/image/icon/icon-strength.png'),
              title: '與龍頭電信營運商合作',
              span: '確保提供高品質的良好網路服務'
            },
            {
              type: 0,
              imgUrl: require('@/assets/image/icon/icon-charge.png'),
              title: '靈活多元的方案',
              span: '讓您可以選擇最適合旅途計畫的網路方案'
            },
            {
              type: 1,
              imgUrl: require('@/assets/image/icon/icon-keep.png'),
              title: '無需更換實體SIM卡',
              span: '避免更換卡片繁雜手續以及中途遺失的風險 '
            },
            {
              type: 0,
              imgUrl: require('@/assets/image/icon/icon-easy.png'),
              title: '安裝簡單',
              span: '僅需3個步驟即可開始使用'
            }
          ],
      // 介绍列表
      testList:
          [
            {
              type: 0,
              title: 'Wang',
              html: '<p>我從eSImart購買了一個日本2GB/天，5天的方案。下飛機只要開啟漫遊就有網路了，不用換卡真的太方便，已經回購多次</p>'
            },
            {
              type: 1,
              title: '吳明峰',
              html: '<p>臨時收到去上海出差通知，上網下單後不到半小時就收到訂單! 感謝eSImart如此迅速出貨，讓我在出差時可以有順暢網路，讓我專注於工作，而不必擔心網絡連接問題。</p>'
            },
            {
              type: 1,
              title: '嘻哈紐克',
              html: '<p>一開始因為安裝太快速都不知道已經安裝完成了，一直掃描不成功，還是發信給客服之後才知道已經裝好了。在日本7天一直瘋狂找路/傳訊息/跟家人視訊，網路都很順暢，沒有卡卡不通順，只有偶而在山區會有部分路段沒網路，但一下子就好了，而且真的很便宜，所以我下次還會再回購的！</p>'
            },
            {
              type: 1,
              title: 'Jessica',
              html: '<p>抵達日本機場之後發現網卡竟然不見了，幸好同行朋友推薦我買esim沒想到還沒出關就收到eSIM了！而且還不貴，真的是小確幸，我下次會乖乖直接買esim的，推推！</p>'
            },
            {
              type: 1,
              title: '歐小金',
              html: '<p>第一次接觸esim一開始看著使用說明，不知道怎麼設定還是有點迷茫，但親切的客服幫我解開第一次使用esim就上手的成就，萬分感謝。</p>'
            },
            {
              type: 1,
              title: 'Rebecca Ko',
              html: '<p>原本不知道該如何選擇方案流量，客服在瞭解了我使用習慣之後推薦我買了歐洲10GB方案，原本擔心不是吃到飽怕會不夠用，但在他們再三保證如果真的有意外可以海外下單而且立刻發貨．但沒想到網路真的很夠用速度又好快 XD 謝謝貼心客服姊姊幫我荷包省錢</p>'
            },
          ],
      // 轮播配置
      swiperOptionTop: {

        loop: true,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        direction:
            "horizontal",
        // 手掌抓去滑动
        grabCursor:
            true,
        pagination: {
          el: ".swiper-pagination", // 分页class
          clickable: true //允许分页点击跳转
        },
      },

      swiperOption:
          {
            // centeredSlides: true,
            // 是否循环
            loop: true,
            // 滑块间隔
            // spaceBetween: 18,
            // 自动播放
            // autoplay: {
            //   delay: 3000,
            //   stopOnLastSlide: false,
            //   disableOnInteraction: false
            // },
            // 滑动速度
            speed:
                800,
            // 滑动方向
            direction:
                "horizontal",
            // 手掌抓去滑动
            grabCursor:
                true,
            // 一屏显示子滑块 自动
            slidesPerView:
                "auto",

            // 显示分页
            // pagination: {
            //   el: ".swiper-pagination", // 分页class
            //   clickable: true //允许分页点击跳转
            // },
            // 设置点击箭头
            // navigation: {
            //   nextEl: ".swiper-button-next",
            //   prevEl: ".swiper-button-prev"
            // }
          }
      ,
      // 简介轮播配置
      swiperOption2: {
        // 是否循环
        loop: false,
        // 滑块间隔
        spaceBetween:
            8,
        // 滑动速度
        speed:
            800,
        // 滑动方向
        direction:
            "horizontal",
        // 手掌抓去滑动
        grabCursor:
            true,
        // 一屏显示子滑块 自动
        slidesPerView:
            "auto",
      }
      ,
      // User Testimonies轮播配置
      swiperOption3: {
        // 是否循环
        loop: true,
        // centeredSlides: true,
        // 滑块间隔
        spaceBetween:
            60,
        // 滑动速度
        speed:
            800,
        // 滑动方向
        direction:
            "horizontal",
        // 手掌抓去滑动
        grabCursor:
            true,
        // 一屏显示子滑块 自动
        slidesPerView:
            "auto",
      }
      ,
      // 产品列表
      dataList: [],
      rate:
          0,// 匯率
      activeIndex: 0,
      openBox: false
    }
        ;
  },


  created() {
    this.getBanner();
    this.getProuductList();
    this.getCurrentRate()
    if (this.$route.query.user_info) {
      let userInfo = JSON.parse(window.atob(this.$route.query.user_info))
      let token = JSON.stringify(userInfo.token)
      window.localStorage.setItem('TOKEN_NAME', token)
      window.localStorage.setItem('userInfo', JSON.stringify(userInfo))
      this.$nextTick(() => {
        this.$store.commit("changeStatus", true);
        this.$store.commit('changgetCart')
        this.$store.commit("changeuserInfo", JSON.stringify(userInfo));
      })
    }
    window.addEventListener('resize', this.getWidth)
  },
  destroyed() {
    window.removeEventListener('resize', this.getWidth)
  },
  mounted(){
    this.getBannerList()
  },
  methods: {
    getWidth() {
      this.isshow = window.innerWidth > 768 ? true : false
    },
    // 跳转至产品详情
    toDetails(item) {
      this.$router.push({
        path: '/details',
        query: {
          id: item.id
        }
      })
    },
    // 加入购物车
    toShop(item) {
      this.$router.push({
        path: '/details',
        query: {
          id: item.id
        }
      })
    },
    // 加入我喜欢
    toLove(item) {

    },

    // 獲取大banner
    async getBannerList() {
      let res = await getBannerList()
      this.homeBanner = res.data
    },
    //獲取首頁小banner
    async getBanner() {
      let res = await getIndexbanner();
      this.imgList = res.data;
    },

    //獲取產品列表
    async getProuductList() {
      let data = {
        type_id: '',
        country_id: '',
        txt: ''
      }
      let res = await getPlanList(data);
      this.dataList = res.data.plan;
    },
    async getCurrentRate() {
      const res = await getCurrentRate({})
      this.rate = Number(res.data.rate)
    },

    handleSlideChange() {
      this.activeIndex = this.$refs.boxSwiper.swiper.realIndex;
      this.openBox = false
    },
    bigBox(i) {
      if (!this.openBox) {
        if (i === this.activeIndex) {
          this.openBox = true
          document.body.style.overflow = 'hidden';
        }
      }

      if (i === 'close') {
        this.openBox = false
        document.body.style.overflow = '';
      }
    }
  }
}
</script>

<style lang="less" scoped>
.home {
  padding-bottom: 90px;

  .home-img {
    .swiper-container {
      margin: 34px 0;
      position: relative;
      width: 100%;
      height: 236px;

      .swiper-slide {
        margin: 0 10px;
        width: 412px;
        height: 100%;
        border-radius: 5px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .home-intro {
    margin: 0 auto;
    padding: 24px 55px 0;
    width: 1064px;
    background: #1D1D1D;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    .home-intro-div {
      flex-basis: calc(100% / 3);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 24px;

      > div {
        flex-grow: 1;
      }

      img {
        margin-right: 20px;
        width: 60px;
        height: 60px;
      }

      .imgs {
        width: 66px;
        height: 66px;
      }

      p {
        margin-bottom: 10px;
        font-family: 'Noto Sans TC';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 29px;
        color: #FFFFFF;
      }

      span {
        font-family: 'Noto Sans TC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #C4C4C4;
      }
    }
  }

  .home-intro-swiper {
    display: none;
  }

  .home-box {
    margin: 40px auto 0;
    width: 1174px;

    .home-box-title {
      margin-bottom: 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      h1 {
        font-family: 'Noto Sans TC';
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 46px;
        color: #FFFFFF;
      }

      div {
        cursor: pointer;
        user-select: none;
        display: flex;
        align-items: center;
        font-family: 'Noto Sans TC';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 30px;
        color: #FFFFFF;

        img {
          width: 30px;
          height: 30px;
        }
      }
    }

    .home-box-list {
      display: flex;
      // align-items: center;
      flex-wrap: wrap;

      .list-div {
        cursor: pointer;
        margin-right: 20px;
        margin-bottom: 45px;
        width: 278px;

        .list-div-img {
          width: 100%;
          height: 186px;
          border-radius: 5px;
          position: relative;

          .img {
            width: 100%;
            height: 100%;
          }

          .shop {
            position: absolute;
            right: 10px;
            bottom: 10px;
            width: 35px;
            height: 35px;
            background: rgba(0, 0, 0, 0.5);
            border-radius: 50%;
            display: none;
            align-items: center;
            justify-content: center;

            img {
              width: 23px;
              height: 23px;
            }
          }

          .love {
            position: absolute;
            right: 10px;
            bottom: 10px;
            width: 35px;
            height: 35px;
            background: rgba(0, 0, 0, 0.5);
            border-radius: 50%;
            display: none;
            align-items: center;
            justify-content: center;

            img {
              width: 22px;
              height: 22px;

            }
          }
        }

        .list-div-h2 {
          padding: 17px 8px 0;
          font-family: 'Noto Sans TC';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          height: 20px;
          color: #00A2FD;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin: 0;
        }

        .list-div-h3 {
          padding: 2px 8px;
          font-family: 'Noto Sans TC';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 23px;
          color: #FFFFFF;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin: 0;
        }

        .list-div-h4 {
          padding: 0 8px;
          font-family: 'Noto Sans TC';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 17px;
          color: #FFC700;
          margin: 0;

          span {
            font-size: 16px;
            line-height: 23px;
          }

          i {
            font-size: 12px;
            line-height: 23px;
            font-style: normal;
            margin-left: 10px;
          }
        }
      }

      .list-div:nth-child(4n+4) {
        margin-right: 0;
      }

      .list-div:hover {
        .list-div-img {
          .shop {
            display: flex;
          }

          .love {
            display: flex;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .home {
    .home-intro {
      width: 890px;
    }

    .home-box {
      width: 1000px;

      .home-box-list {
        .list-div {
          width: calc((100% - 40px) / 3);
        }

        .list-div:nth-child(4n+4) {
          margin-right: 20px;
        }

        .list-div:nth-child(3n+3) {
          margin-right: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .home {
    .home-intro {
      padding: 14px 20px;
      width: 740px;

      .home-intro-div {
        img {
          margin-right: 10px;
          width: 40px;
          height: 40px;
        }

        .imgs {
          width: 44px;
          height: 44px;
        }

        p {
          margin-bottom: 5px;
          font-size: 16px;
          line-height: 20px;
        }

        span {
          font-size: 12px;
          line-height: 16px;
        }
      }
    }

    .home-box {
      width: 780px;

      .home-box-list {
        .list-div {
          width: calc((100% - 40px) / 3);

          .list-div-img {
            width: 100%;
            height: 164px;
          }
        }

        .list-div:nth-child(4n+4) {
          margin-right: 20px;
        }

        .list-div:nth-child(3n+3) {
          margin-right: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .home {
    .home-img {
      .swiper-container {
        margin: 16px 0;
        position: relative;
        width: 100%;
        height: 140px;

        .swiper-slide {
          margin: 0 7.5px;
          width: 246px;
          height: 100%;
          border-radius: 5px;
        }
      }
    }

    .home-intro {
      display: none;
    }

    .home-intro-swiper {
      display: block;

      .swiper-container {
        padding: 0 7.5px;

        .swiper-slide {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 280px;
          height: 65px;
          background: #1D1D1D;
          border-radius: 10px;
          padding: 5px 10px;

          img {
            margin-right: 9px;
            // width: 38px;
            // height: 38px;
            width: 30px;
            height: 30px;
          }

          div {
            p {
              font-family: 'Noto Sans TC';
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 23px;
              color: #FFFFFF;
              font-size: 14px;
              line-height: 18px;
            }

            span {
              font-family: 'Noto Sans TC';
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              font-size: 10px;
              line-height: 17px;
              color: #C4C4C4;
            }
          }
        }
      }
    }

    .home-box {
      padding: 0 7.5px;
      width: calc(100% - 15px);

      .home-box-title {
        margin-bottom: 12px;

        h1 {
          font-size: 16px;
          line-height: 23px;
        }

        div {
          font-size: 12px;
          line-height: 17px;

          img {
            width: 23px;
            height: 23px;
          }
        }
      }

      .home-box-list {
        .list-div {
          width: calc((100% - 20px) / 2);

          .list-div-img {
            width: 100%;
            height: 105px;
          }

          .list-div-h2 {
            padding: 12px 0 0;
            font-size: 14px;
            line-height: 14px;
          }

          .list-div-h3 {
            padding: 3px 0 0;
            font-size: 14px;
            line-height: 20px;
          }

          .list-div-h4 {
            padding: 0;
            font-size: 10px;
            line-height: 15px;

            span {
              font-size: 14px;
              line-height: 20px;
            }

            i {
              font-size: 10px;
              line-height: 20px;
              margin-left: 6px;
            }
          }
        }

        .list-div:nth-child(3n+3) {
          margin-right: 20px;
        }

        .list-div:nth-child(2n+2) {
          margin-right: 0;
        }

        .list-div:hover {
          .list-div-img {
            .shop {
              display: none;
            }

            .love {
              display: none;
            }
          }
        }
      }
    }
  }
}

.button1 {
  background: #0B57C9;
  cursor: pointer;
  user-select: none;
  width: 199px;
  height: 37px;
  border-radius: 20px;
  font-family: 'Noto Sans TC';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto 0;
}
</style>
